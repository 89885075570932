<template>
  <v-container>
    <v-row class="mt-5 justify-center">
      <v-col
        v-if="valid"
        cols="12"
        md="7"
        lg="5"
      >
        <v-card class="mb-0">
          <v-card-text class="p-5">
            <h2 class="mb-5">
              {{ $t('receivedInvitationFrom') }} {{ sender }} {{ $t('toJoin') }} {{ orgName }}.
            </h2>
            <v-btn
              id="accept-invite"
              color="blue"
              dark
              class="text-capitalize"
              @click="acceptInvite"
            >
              {{ $t('accept') }}
            </v-btn>
            <v-btn
              id="decline-invite"
              color="gray-100"
              dark
              class="text-capitalize ml-2"
              style="color: black;"
              @click="declineInvite"
            >
              {{ $t('decline') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('user');
import makeOrgService from '@/services/api/org';

let orgService;

export default {
  data() {
    return {
      orgName: '',
      valid: false,
      sender: '',
    };
  },
  created() {
    const handle = this.$route.params.handle;
    const token = this.$route.params.token;
    orgService = makeOrgService(this.$api);
    orgService
      .validateInvite({ handle, token })
      .then((response) => {
        this.valid = true;
        this.orgName = response.data.name;
        this.sender = response.data.sender.firstName + ' ' + response.data.sender.lastName;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return this.$router.push({ name: 'Login' });
        }
        this.$swal({
          title: error.response?.data?.message ?? error.message,
          icon: 'error',
          showConfirmButton: false,
          position: 'center',
          timer: 2000,
          toast: true,
        });
        this.$router.replace('/');
      });
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    acceptInvite() {
      orgService
        .acceptInvite({
          handle: this.$route.params.handle,
          token: this.$route.params.token,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              title: this.$t('acceptedInvitation'),
              icon: 'success',
              showConfirmButton: false,
              position: 'top-center',
              timer: 2000,
              toast: true,
            }).then(() => {
              this.$router.replace('/');
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: error.response?.data?.message || error.message,
            icon: 'error',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          });
        });
    },
    declineInvite() {
      return alert('rejected');

      // orgService
      //   .declineInvite({
      //     handle: this.$route.params.handle,
      //     token: this.$route.params.token,
      //   })
      //   .then((response) => {
      //     if (response.status === 200) {
      //       this.$swal({
      //         title: 'Declined invitation',
      //         icon: 'warning',
      //         showConfirmButton: false,
      //         position: 'top-center',
      //         timer: 2000,
      //         toast: true,
      //       }).then(() => {
      //         this.$router.replace('/');
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>
